.split-pane-frame {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.split-pane-left {
  text-align: left;
  width: 600px;
  margin:5px;
  overflow-x: hidden;
  overflow-y: auto;
}

.split-pane-right {
  flex: 1;
  text-align: left;
  margin: 10px;
  overflow: auto;
}

.split-pane-top {
  text-align: left;
  justify-content: start;
  word-wrap: break-word;
}

.split-pane-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  word-wrap: break-word;
}

.memo-container {
  margin: -1rem 0 -1rem 10px !important;
  padding: 0 !important;
}

.separator-col {
  border: 5px solid navy;
  cursor: col-resize;
}

.separator-row {
  border: 2px solid navy;
}

.PDF-container {
  display: flex;
  height: 100vh !important;
  overflow: auto;
}